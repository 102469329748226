function convertPubDate(blogPost) {
  const unixPubDate = new Date(blogPost.publishDate).getTime();
  return unixPubDate;
}
function sortNewestBlogPost(firstBlog, secondBlog) {
  const unixCurrentDate = Math.floor(Date.now());
  const timeSinceFirstBlog = unixCurrentDate - convertPubDate(firstBlog);
  const timeSinceSecondBlog = unixCurrentDate - convertPubDate(secondBlog);
  return timeSinceFirstBlog - timeSinceSecondBlog;
}

export const getFeaturedBlogPost = blogPosts => {
  const filteredPosts = blogPosts.filter(blog => {
    if (blog.isFeaturePriority === null) {
      blog.isFeaturePriority = false;
      return blog.isFeaturePriority;
    }
    return blog.isFeaturePriority;
  });

  const sortedPosts = filteredPosts.sort((firstBlog, secondBlog) => {
    const intPriorityA = firstBlog.isFeaturePriority ? 1 : 0;
    const intPriorityB = secondBlog.isFeaturePriority ? 1 : 0;
    if (intPriorityA === intPriorityB) {
      return sortNewestBlogPost(firstBlog, secondBlog);
    }
    return intPriorityB - intPriorityA;
  });
  return sortedPosts[0];
};
